<template>
  <div class="rider-tracking-sidebar">
    <div class="rider-tracking-sidebar--content">
      <v-form ref="form">
        <v-switch
          id="contract-rider-tracking-enabled"
          v-model="riderTrackingEnabled"
          label="Live"
          class="live-switch"
          hide-details
        />
        <v-checkbox
          v-if="riderTrackingEnabled"
          v-model="multipleRiderTicketTrip"
          label="Enable Multiple Tickets Per Trip"
          hide-details
        />
        <v-checkbox
          v-if="riderTrackingEnabled && isRiderManualAddEnabled"
          v-model="canManuallyAddRiders"
          label="Enable Manually Adding Riders From the Driver App"
          hide-details
        />
        <v-checkbox
          v-if="riderTrackingEnabled && isRiderIdTicketsEnabled"
          v-model="hasRiderIdTickets"
          label="Enable Rider ID Tickets"
          hide-details
        />
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="rider-tracking-save-btn"
        :loading="submitting"
        class="rider-tracking-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import contracts from '@/services/contracts'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    contract: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      riderTrackingEnabled: false,
      multipleRiderTicketTrip: false,
      canManuallyAddRiders: false,
      hasRiderIdTickets: false,
    }
  },
  computed: {
    isRiderManualAddEnabled() {
      const { getters = {} } = this.$store
      return getters['featureToggles/isRiderManualAddEnabled']
    },
    isRiderIdTicketsEnabled() {
      const { getters = {} } = this.$store
      return getters['featureToggles/isRiderIdTicketsEnabled']
    },
  },
  async mounted() {
    this.riderTrackingEnabled = this.contract?.hasRiderTracking
    this.multipleRiderTicketTrip = this.contract?.multipleRiderTicketTrip
    this.canManuallyAddRiders = this.contract?.canManuallyAddRiders
    this.hasRiderIdTickets = this.contract?.hasRiderIdTickets
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      if (this.multipleRiderTicketTrip && this.hasRiderIdTickets) {
        this.showAlert({
          type: 'error',
          message: 'Cannot enable both multiple tickets and rider ID tickets.',
        })
        return
      }
      const payload = {
        quoteId: this.contract.contractId,
        enabled: this.riderTrackingEnabled,
        multipleRiderTicketTrip:
          this.riderTrackingEnabled && this.multipleRiderTicketTrip,
        canManuallyAddRiders:
          this.riderTrackingEnabled && this.canManuallyAddRiders,
        hasRiderIdTickets:
          this.isRiderIdTicketsEnabled && this.hasRiderIdTickets,
      }
      try {
        const response = await contracts
          .updateRiderTracking(payload)
          .catch((e) => e.response)
        this.$store.dispatch('app/showAlert', {
          type: response.data.successful ? 'success' : 'error',
          message: response.data.successful
            ? 'Rider Tracking Updated.'
            : response.data.message,
        })
        EventBus.$emit('global-table-view-refresh')
        this.close()
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Error updating rider tracking.',
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.rider-tracking-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
